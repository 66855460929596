import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import "magnific-popup";


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {

  AOS.init({
    once: true, // whether animation should happen only once - while
  });

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
      $('.back-to-top').show();
    } else {
      $('.header').removeClass("min-header");
      $('.back-to-top').hide();
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $(".header-menu-link").hover(function () {
    if (window.innerWidth > 1280) {
      let isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children('.header-menu-dropdown').stop().slideDown(300);
      } else {
        $(this).children('.header-menu-dropdown').stop().slideUp(300);
        $(this).parents('.header').removeClass('dropdown-show');
      }
    }
  });

  $('.dropdown .menu-link').click(function(e) {
    if (window.innerWidth  <= 1280) {
      e.preventDefault();
      if ($(this).parent('.header-menu-link').hasClass('active')) {
        $(this).siblings('.header-menu-dropdown').stop().slideUp(300);
        setTimeout(() => {
          $(this).parent('.header-menu-link').removeClass('active');
        }, 300);
      } else {
        $(this).parent('.header-menu-link').addClass('active');
        $(this).siblings('.header-menu-dropdown').stop().slideDown(300);
      }
    }
  });

  $('.dropdown-link.dropdown-group').click(function(e) {
    if (window.innerWidth  <= 1280) {
      e.preventDefault();
      if ($(this).hasClass('active')) {
        $(this).siblings('.second-dropdown-links').slideUp(300);
        setTimeout(() => {
          $(this).removeClass('active');
        }, 300);
      } else {
        $(this).addClass('active');
        $(this).siblings('.second-dropdown-links').slideDown(300);
      }
    }
  });

  // dropdown
  $('.dropdown-btn').on('click', function () {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $('[aria-labelledby=' + id + ']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '',
          'overflow': '',
        })
      }
    } else {
      $(".dropdown-list:not([aria-labelledby=" + id + "]").removeClass('is-active');
      $('[aria-labelledby=' + id + ']').addClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '100vh',
          'overflow': 'hidden',
        })
      }
    }
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if ($('.dropdown-list').is(":visible")) {
        $(".dropdown-list").removeClass('is-active');
        if (window.innerWidth <= 640) {
          $('body').css({
            'height': '',
            'overflow': '',
          })
        }
      }
    }
  });

  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 4000,
    },
    loop: true,
    peed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });

  var certList = new Swiper('#certList', {
    slidesPerView: 'auto',
    direction: "vertical",
    watchSlidesProgress: true,
  });

  var certImgs = new Swiper('#certImgs', {
    slidesPerView: 2.7,
    spaceBetween: 30,
    breakpoints: {
      0: {
        slidesPerView: 1.35,
        slidesOffsetBefore: 50,
        slidesOffsetAfter: 50,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 1.8,
        slidesOffsetBefore: 50,
        slidesOffsetAfter: 50,
      },
      840: {
        slidesPerView: 2.7,
        slidesOffsetBefore: 50,
        slidesOffsetAfter: 50,
      },
      2000: {
        slidesOffsetBefore: 50,
        slidesOffsetAfter: 50,
        slidesPerView: 3,
      },
    },
    thumbs: {
      swiper: certList,
    },
  });

  var serviceImgs = new Swiper('#serviceImgs', {
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
    },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });


  var indexShowcases = new Swiper('#indexShowcases', {
    slidesPerView: 2.6,
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-btn-next",
      prevEl: ".swiper-btn-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1.2,
      },
      640: {
        slidesPerView: 1.8,
      },
      1200: {
        slidesPerView: 2.2,
      },
      2001: {
        slidesPerView: 3,
      }
    },
  });

  var showcaseImgs = new Swiper('#showcaseImgs', {
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
    },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  var related_cases = ['container_contract', 'steel_structure', 'ship_contract', 'pipeline']

  $.each(related_cases, function (key, value) {
    var value = new Swiper('#' + value +'_swiper .swiper', {
      slidesPerView: 4,
      spaceBetween: 5,
      navigation: {
        nextEl: "#"+ value +"_swiper .swiper-btn-next",
        prevEl: "#" + value +"_swiper .swiper-btn-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 4,
        },
        640: {
          slidesPerView: 5,
        },
        800: {
          slidesPerView: 4,
        },
        1200: {
          spaceBetween: 7,
          slidesPerView: 5,
        },
      },
    });
  });

  // milestone
  if ($('#milestoneYears').length > 0) {
    var totalWidth = 0;
    $('.milestone-year').each(function (index) {
      totalWidth += $(this).width();
      return totalWidth;
    });
    if ($('#milestoneYears').width() > totalWidth + 20) {
      $('#milestoneYears .swiper-wrapper').css('justify-content', 'center')
    }
  }

  var milestoneYears = new Swiper('#milestoneYears', {
    slidesPerView: 'auto',
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    watchSlidesProgress: {
      clickable: true,
    },
    navigation: {
      nextEl: ".milestone-next",
      prevEl: ".milestone-prev",
    },
  });

  var getSlide = $("#milestoneCards").data("final-year");

  var milestoneCards = new Swiper('#milestoneCards', {
    slidesPerView: 1.3,
    spaceBetween: 5,
    watchSlidesProgress: true,
    hashNavigation: {
      watchState: true,
    },
    initialSlide: getSlide,
    breakpoints: {
      0: {
        slidesPerView: 1.3,
        slidesOffsetBefore: 50,
        slidesOffsetAfter: 50,
      },
      640: {
        slidesPerView: 2,
        centeredSlides: true,
      },
      1200: {
        slidesPerView: 3,
        centeredSlides: true,
      },
    },
    thumbs: {
      swiper: milestoneYears,
    },
  });

  var aboutVideo = new Swiper('#aboutVideo', {
    slidesPerView: 1.3,
    spaceBetween: 5,
    centeredSlides: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".video-next",
      prevEl: ".video-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1.3,
      },
      640: {
      slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  });

  //form input
  document.querySelectorAll('input, select, textarea').forEach(input => {
    input.addEventListener('input', () => {
      if (input.value.length >= 0) {
        input.classList.add('focused')
        input.labels.forEach(label => {
          label.classList.add('focused')
        })
      };
    });

    input.addEventListener('focus', () => {
      input.classList.add('focused')
      input.labels.forEach(label => {
        label.classList.add('focused')
      })
    });

    input.addEventListener('blur', () => {
      if (input.value.length <= 0) {
        input.classList.remove('focused')
        input.labels.forEach(label => {
          label.classList.remove('focused')
        })
      };
    });
  });

  // video
  $('.popup-video').magnificPopup({
    type: 'iframe'
  });
});
